import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {USER_KEY} from '../app/services/token-storage.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Malama';
  currentUser:any = {};
  constructor(public router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUser = JSON.parse(localStorage.getItem(USER_KEY));

        if (!this.currentUser && event.url.indexOf("restablecer-pass")==-1  && event.url.indexOf("activacion")==-1) {
          this.router.navigate(["login", {}]);
        }
      }
    });
  }
}
