
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Servers } from '../config/api';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
const TOKEN_KEY = 'auth-token';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginUrl = Servers.MALAMA.baseUrl + 'login';
  private resetPassUrl = Servers.MALAMA.baseUrl + 'password_reset/confirm/'
  private activacionUrl = Servers.MALAMA.baseUrl + 'register/activacion'
  constructor(private http: HttpClient) { }

  login(credentials): Observable<any> {
    var body = {
      email: credentials.email,
      password: credentials.password,
      is_admin:credentials.is_admin,
      version:credentials.version,
    }
    return this.http.post(this.loginUrl,body, httpOptions);
  }

  resetPass(credentials): Observable<any> {
    var body = {
      password: credentials.password,
      token: credentials.token,
    }
    return this.http.post(this.resetPassUrl,body, httpOptions);
  }

  activar(token): Observable<any> {
    var body = {
      token: token
    }
    return this.http.post(this.activacionUrl,body, httpOptions);
  }

  public getUserToken(): any {
      return localStorage.getItem(TOKEN_KEY);
  }

}
