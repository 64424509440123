import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-restablecer-pass',
  templateUrl: './restablecer-pass.component.html',
  styleUrls: ['./restablecer-pass.component.css']
})
export class RestablecerPassComponent implements OnInit {
  resetForm: FormGroup;
  token:any = "";
  isSubmitted = false;
  constructor(private route: ActivatedRoute,private authService: AuthService,private ngxService: NgxUiLoaderService,private toastr: ToastrService, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-primary");
    body.classList.add("bg-login");
    this.token = this.route.snapshot.params.token;
    localStorage.clear();
    this.resetForm = this.formBuilder.group({
      password: ['', Validators.required],
      token:[this.token]
    });
  }

  reset() {
    this.isSubmitted = true;
    if (this.resetForm.invalid) {
      return;
    } 
    this.ngxService.start();
    this.authService.resetPass(this.resetForm.value).subscribe(
      data => { 
        this.ngxService.stop();
        if(data.status=="OK"){
          this.toastr.success("¡La contraseña se restableció con éxito!"); 
        } 
      },
      err => {
        if(err){
          if(err.error){
            if(err.error.password){
              if(err.error.password.length>0){
                this.toastr.info("La contraseña es muy corta. Debe contener al menos 8 caracteres y combinaciones de letras y números.");
              }
            }
            if(err.error.status=="notfound")
            this.toastr.info("La url ya no es válida, vuelva a la aplicación a realizar el pedido de restablecimiento."); 
          }
        }
      }
    );
  }

  get formControls() { return this.resetForm.controls; }

}
