import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-activacion-cuenta',
  templateUrl: './activacion-cuenta.component.html',
  styleUrls: ['./activacion-cuenta.component.css']
})
export class ActivacionCuentaComponent implements OnInit {
  token: any = "";
  isSubmitted = false;
  constructor(private route: ActivatedRoute, private authService: AuthService, private ngxService: NgxUiLoaderService, private toastr: ToastrService, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-primary");
    body.classList.add("bg-login");
    this.token = this.route.snapshot.params.token;
    localStorage.clear();
  
  }
 
  activar() {
    this.ngxService.start();
    this.authService.activar(this.token).subscribe(
      data => {
        this.ngxService.stop();
        if(data.estado==0){
          this.toastr.success("Cuenta activada, ahora puedes ingresar a la app con tus credenciales");

        }
      },
      err => {
        this.ngxService.stop();
        this.toastr.info("Ocurrió un error inesperado, intentalo de nuevo");
      }
    );
  }

}
