import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//Loader
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig
} from 'ngx-ui-loader';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { DataTablesModule } from 'angular-datatables';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ErrorInterceptor} from './utils/auth-error.interceptor';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { PublicacionesComponent } from './pages/publicaciones/publicaciones.component';
import { DetallePublicacionComponent } from './pages/detalle-publicacion/detalle-publicacion.component';
import { ToastrModule } from "ngx-toastr";
import {AuthInterceptor} from './utils/auth.interceptor';
import { RestablecerPassComponent } from './pages/restablecer-pass/restablecer-pass.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PoliticasPrivacidadComponent } from './pages/politicas-privacidad/politicas-privacidad.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/es';
import { ThousandsPipe } from './pipe/separadormiles';
import { HighchartsChartModule } from 'highcharts-angular';
import { MapaComponent } from './pages/mapa/mapa.component';
import { ActivacionCuentaComponent } from './pages/activacion-cuenta/activacion-cuenta.component';

registerLocaleData(localeDe);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#437a73',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'rectangle-bounce',
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#437a73',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'rectangle-bounce',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'rectangle-bounce',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#437a73',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300
};

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    ComponentsModule,
    NgbModule,
    RouterModule,
    HighchartsChartModule,
    AppRoutingModule,
    DataTablesModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    TranslateModule.forRoot()
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    PublicacionesComponent,
    ThousandsPipe,
    DetallePublicacionComponent,
    RestablecerPassComponent,
    PoliticasPrivacidadComponent,
    UsuariosComponent,
    MapaComponent,
    ActivacionCuentaComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es" },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
