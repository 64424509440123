import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PublicacionesService } from '../../services/publicaciones.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Gallery } from 'angular-gallery';
import Swal from 'sweetalert2'
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-detalle-publicacion',
  templateUrl: './detalle-publicacion.component.html',
  styleUrls: ['./detalle-publicacion.component.css']
})
export class DetallePublicacionComponent implements OnInit {
  publicacion: any = {};
  detalle: any = {};
  articulos: any = [];
  articulosConfirmados: boolean = false;
  idPublicacion: any;
  constructor(private ngxService: NgxUiLoaderService, private route: ActivatedRoute, public publicacionesService: PublicacionesService, private http: HttpClient, private router: Router, private gallery: Gallery) { }

  ngOnInit(): void {
    this.idPublicacion = this.route.snapshot.params.id;
    this.getDetalle();
  }

  showGallery(imagen, index: number, articuloId) {
    let prop = {
      images: [

      ],
      index
    };
    console.log(articuloId)
    for (let i = 0; i < this.articulos.length; i++) {
      if (articuloId == this.articulos[i].id) {
        let imagenes = this.articulos[i].imagenes;
        imagenes.forEach(element => {
          prop.images.push({
            path: element.url
          });
        });
        this.gallery.load(prop);
      }

    }
  }

  aprobarPublicacion() {
    this.ngxService.start();
    this.publicacionesService.confirmar(this.idPublicacion).subscribe(
      (data) => {
        this.ngxService.stop();
        if (data.estado === 0) {
          this.router.navigate(['publicaciones']);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ocurrió un error inesperado',
            text: 'Contacta con el servicio técnico para solucionar el problema.',
            allowOutsideClick: false
          })
        }
      },
      (error) => {
        console.log(error.error.mensaje);
      }
    );
  }

  aprobarArticulo(articulo) {
    this.ngxService.start();
    this.publicacionesService.confirmarArticulo(articulo.id).subscribe(
      (data) => {
        this.ngxService.stop();
        if (data.estado === 0) {
          this.getDetalle();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ocurrió un error inesperado',
            text: 'Contacta con el servicio técnico para solucionar el problema.',
            allowOutsideClick: false
          })
        }
      },
      (error) => {
        console.log(error.error.mensaje);
      }
    );
  }

  rechazarArticulo(articulo) {
    Swal.fire({
      title: 'Motivo de rechazo',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      cancelButtonText: 'Cerrar',
      confirmButtonColor: '#437a73',
      showLoaderOnConfirm: true,
      preConfirm: (comentario) => {

      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        var body = {
          "articulo": articulo.id,
          "comentario": result.value
        }
        console.log(body);
        this.ngxService.start();
        this.publicacionesService.rechazarArticulo(body).subscribe(
          (data) => {
            this.ngxService.stop();
            console.log(data);
            if (data.estado === 0) {
              this.getDetalle();
            } else {
              Swal.fire(
                'Ocurrió un error',
                'error'
              );
            }
          },
          (error) => {
            console.log(error.error.mensaje);
          }
        );
        console.log(result);

      }
    })
  }


  getDetalle() {
    this.ngxService.start();
    this.publicacionesService.getArticulos(this.idPublicacion).subscribe(response => {
      this.ngxService.stop();
      if (response) {
        this.publicacion = response.data;
        this.detalle = response.data;
        this.articulos = this.detalle.articulos
        let cantidadAprobados = 0;
        if (this.articulos != null) {
          for (let index = 0; index < this.articulos.length; index++) {
            const element = this.articulos[index];
            if (element.articuloestadopublicacion == 2 || element.articuloestadopublicacion == 5 || element.articuloestadopublicacion == 3) {
              cantidadAprobados++;
            }
          }
          if (cantidadAprobados == this.articulos.length) {
            this.articulosConfirmados = true;
          } else {
            this.articulosConfirmados = false;
          }
        }

      }
    });
  }

}
