import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PublicacionesService } from '../../services/publicaciones.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';



@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicaciones.component.html',
  styleUrls: ['./publicaciones.component.css']
})
export class PublicacionesComponent implements OnInit {

  public datasets: any;
  public data: any;
  public salesChart;
  public clicked: boolean = true;
  public clicked1: boolean = false;
  orden=false;
  publicaciones: any = [];

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();


  constructor(private ngxService: NgxUiLoaderService, public publicacionesService: PublicacionesService, private http: HttpClient, private router: Router,) {
  
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 20,
      searching: false,
      columnDefs: [{
        targets: [4],
        orderable: false
      }],
      language: {
        lengthMenu: "Mostrar _MENU_ por pág.",
        zeroRecords: "No hay datos disponibles",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "No hay datos disponibles",
        infoFiltered: "(filtrado de _MAX_ datos)",
        paginate: {
          first: "Primera pág.",
          last: "Ultima pág.",
          next: "Siguiente",
          previous: "Anterior"
        },
      },
    };
    this.obtenerPublicaciones(null);
  }

  verDetalle(publi) {
    console.log(publi);
    this.ngxService.start();
    this.publicacionesService.getArticulos(publi.id).subscribe(response => {
      this.ngxService.stop();
      if (response) {
        this.router.navigate(['articulos/' + publi.id]);
        console.log(response);
      }
    });
  }



  obtenerPublicaciones(orden) {
    this.ngxService.start();
    this.publicacionesService.getPublicaciones(orden).subscribe(response => {
      this.ngxService.stop();
      if (response) {
        this.publicaciones = response.data;
        $('#target').one('click', '.order',(event)=> {
          if(event.target.id!=""){
            if(event.target.id=="fechaCreacion"){
              var orden = {
                "direccion": this.orden ? "asc":"desc", 
                "valor":"fecha_creacion"
              }
              this.orden=!this.orden;
              this.obtenerPublicaciones(orden);
            }
          }
        });
      }
    });
  }

}
