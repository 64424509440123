import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DetallePublicacionComponent } from 'src/app/pages/detalle-publicacion/detalle-publicacion.component';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: 'dashboard', title: 'Inicio',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: 'publicaciones', title: 'Publicaciones',  icon:'ni-bullet-list-67 text-primary', class: '' },
    { path: 'usuarios', title: 'Usuarios',  icon:'ni-single-02 text-primary', class: '' },
    { path: 'mapa', title: 'Mapa de usuarios',  icon:'ni-single-02 text-primary', class: '' },
    { path: 'articulos',title:'Artículos',  icon:'ni-bullet-list-67 text-red', class: 'd-none' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
}
