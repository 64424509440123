import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Servers } from '../config/api';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private userUrl = Servers.MALAMA.baseUrl + 'usuarios';
  private sumarizacionUrl = Servers.MALAMA.baseUrl + 'administracion/sumarizacion';
  private sumarizacionUrl2 = Servers.MALAMA.baseUrl + 'administracion/sumarizacion-geo';
  constructor(private http: HttpClient) { }

  getAll(data): Observable<any> {
    return this.http.post<any[]>(this.userUrl + '/all',data, httpOptions);
  }
  getAllData(): Observable<any> {
    return this.http.get<any[]>(this.sumarizacionUrl, httpOptions);
  }
  getAllDataGeo(): Observable<any> {
    return this.http.get<any[]>(this.sumarizacionUrl2, httpOptions);
  }
}
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Token ' + localStorage.getItem(TOKEN_KEY) })
};