import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Servers } from '../config/api';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
const TOKEN_KEY = 'auth-token';

class Publicacion{
  id: number;
  cantidadArticulos: number;
  estadoPublicacion_id: number;
  montoTotal: number;
}

@Injectable({
  providedIn: 'root'
})


export class PublicacionesService {
  private publicacionesUrl = Servers.MALAMA.baseUrl + 'publicaciones';
  private publicacionUrl = Servers.MALAMA.baseUrl + 'publicacion';
  private articulosUrl = Servers.MALAMA.baseUrl + 'articulos';
 
  constructor(private http: HttpClient) { }

  confirmar(id): Observable<any> {
    var body = {
      publicacion: id
    }
    return this.http.post(this.publicacionesUrl + '/confirmar',body, httpOptions);
  }

  getPublicaciones(orden:any): Observable<any> {
    return this.http.post<Publicacion[]>(this.publicacionesUrl + '/pendientes-confirmacion',orden, httpOptions);
  }

  getDetalle(id): Observable<any> {
    return this.http.get<Publicacion[]>(this.publicacionesUrl + '/' + id, httpOptions);
  }

  getArticulos(id): Observable<any> {
    return this.http.get<Publicacion[]>(this.publicacionUrl + '/articulos/' + id, httpOptions);
  }

  confirmarArticulo(id): Observable<any> {
    var body = {
      articulo: id
    }
    return this.http.post(this.articulosUrl + '/confirmar',body, httpOptions);
  }

  rechazarArticulo(body): Observable<any> {
    return this.http.post(this.articulosUrl + '/rechazar',body, httpOptions);
  }

  public getUserToken(): any {
    return localStorage.getItem(TOKEN_KEY);
  }
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Token ' + localStorage.getItem(TOKEN_KEY) })
};