import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js';
import { PublicacionesService } from '../../services/publicaciones.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "../../variables/charts";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { DataTableDirective } from 'angular-datatables';
class Publicacion {
  id: number;
  cantidadArticulos: number;
  estadoPublicacion_id: number;
  montoTotal: number;
}


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  public datasets: any;
  public data: any;
  public salesChart;
  public clicked: boolean = true;
  public clicked1: boolean = false;
  usuarios: any = [];

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  orden = false;


  constructor(private ngxService: NgxUiLoaderService, public usuariosService: UsuariosService, private http: HttpClient, private router: Router,) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 20,
      searching: true,
      order: [],
      columnDefs: [{ targets: 3, searchable: true, orderable: false, type: 'date' }],
      language: {
        lengthMenu: "Mostrar _MENU_ por pág.",
        zeroRecords: "No hay datos disponibles",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "No hay datos disponibles",
        infoFiltered: "(filtrado de _MAX_ datos)",
        paginate: {
          first: "Primera pág.",
          last: "Ultima pág.",
          next: "Siguiente",
          previous: "Anterior"
        },
      }
    };
    this.obtenerUsuarios(null);
  }

  ngAfterViewInit(): void {
    //this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  obtenerUsuarios(orden) {

    this.ngxService.start();
    this.usuariosService.getAll(orden).subscribe(response => {
      if (response) {
        this.ngxService.stop();
        this.usuarios = response.data;
        if(orden==null){
          this.dtTrigger.next();
        }else{
          this.rerender();
        }
        $('#target').one('click', '.order', (event) => {
          if (event.target.id != "") {
            var orden = {
              "direccion": this.orden ? "asc" : "desc",
              "valor": event.target.id
            }
            this.orden = !this.orden;
            this.obtenerUsuarios(orden);
          }
        });
      }
    });



  }
}



